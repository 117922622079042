import { render, staticRenderFns } from "./vuetiform-select.vue?vue&type=template&id=75ce1f57&scoped=true&"
import script from "./vuetiform-select.vue?vue&type=script&lang=js&"
export * from "./vuetiform-select.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../@vue-modules/vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75ce1f57",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../@vue-modules/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAvatar,VChip,VIcon,VListItemContent,VListItemTitle,VSelect})

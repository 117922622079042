<template>
    <div id="navigation">
        <notification_snackbars :enabled="snackbarEnabled" />
        <v-app-bar app color="primary">
            <v-toolbar-items>
                <v-app-bar-nav-icon
                    @click="
                        drawer = true;
                        sidebarclick();
                    "
                ></v-app-bar-nav-icon>

                <v-btn
                    v-for="(route, ix) in toolbar_routes()"
                    text
                    :to="open(route)"
                    :key="ix"
                    class="d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex"
                    :title="route.name.toUpperCase()"
                >
                    <v-icon> {{ route.icon }} </v-icon>
                </v-btn>
                <v-btn text @click="snackbarEnabled = !snackbarEnabled" class="d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex"
                    ><v-icon v-if="snackbarEnabled"> mdi-information </v-icon><v-icon v-if="!snackbarEnabled"> mdi-information-outline </v-icon></v-btn
                >
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn text v-if="$store.state.server.session.user" v-on:click="$root.login_dialog.open()" class="d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex">{{
                $store.state.server.session.user.name
            }}</v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" fixed temporary class="navigation-sidebar">
            <language_selector/>
            <v-list nav dense>
                <v-list-item-group active-class="primary--text">
                    <v-list-item>
                        <v-icon>mdi-beta</v-icon>
                       <v-list-item-title class="text-uppercase">{{ version }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="route in sidebar_routes()" :to="open(route)" :key="route.path">
                        <v-icon>{{ route.icon }}</v-icon>
                        <v-list-item-title class="text-uppercase"> {{ route.name }}</v-list-item-title>
                    </v-list-item>

                    

                    <v-list-item @click="open_uri('manual-en.html')">
                        <v-icon>mdi-flag</v-icon>
                        <v-list-item-title>User Manual</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="open_uri('manual-hu.html')">
                        <v-icon>mdi-flag</v-icon>
                        <v-list-item-title>Felhasználói Kézikönyv</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <checkpoint_routes />
        </v-navigation-drawer>
    </div>
</template>

<script>
import notification_snackbars from "@/notification-snackbars.vue";
import language_selector from "@/multilanguage-components/LanguageSelector.vue";
import checkpoint_routes from "@/checkpoint-components/checkpoint-routes.vue";

export default {
    name: "navigation",
    props: {},
    data: function () {
        return {
            drawer: false,
            debug: ß.DEBUG,
            error: false,
            snackbar: false,
            snackbarEnabled: true,
            log: "",
            msg: "",
            version: ß.BUILD_VERSION,
        };
    },
    components: {
        notification_snackbars,
        language_selector,
        checkpoint_routes,
    },
    methods: {
        sidebarclick() {
            this.$socket.client.emit("navigation-sidebarclick");
        },
        logout() {},
        open_editor() {
            window.open(ß.EDITOR_SITELINK, "_blank");
        },

        open_readme() {
            window.open("https://" + ß.HOSTNAME + "/README.html", "_blank");
        },
        open_uri(uri) {
            window.open("https://" + ß.HOSTNAME + "/" + uri, "_blank");
        },
        open(route) {
            if (route.open) return route.open;
            const path = route.path.split(":")[0].split("*")[0];
            return path;
        },
        sidebar_routes() {
            return this.$store.state.navigation.routes;
        },
        toolbar_routes() {
            return this.$store.state.navigation.routes.filter(e => e.toolbar === true);
        },
    },
};
</script>

<style>
#snackbar {
    padding-bottom: 50px !important;
}
#navigation {
    z-index: 100;
}
</style>

<template>
    <div>
        <v-select
            v-if="select"
            :key="key"
            v-model="model"
            :items="items"
            :clearable="clearable"
            :label="label"
            :hint="hint"
            @input="update"
            append-outer-icon="mdi-arrow-down-drop-circle"
            @click:append-outer="select = !select"
            :prepend-icon="prependIcon"
        >
        </v-select>
        <v-text-field
            v-else
            :key="key"
            v-model="model"
            :clearable="clearable"
            :label="label"
            :hint="hint"
            @input="update"
            append-outer-icon="mdi-pencil"
            @click:append-outer="select = !select"
            :prepend-icon="prependIcon"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    //            "prepend-icon": "mdi-code-parentheses",

    name: "vuetiform-designate",
    props: ["value", "items", "clearable", "label", "hint", "readonly", "prepend-icon"],
    data: function () {
        return {
            model: this.value || "",
            select: this.items.includes(this.value),
            key: 0,
        };
    },
    mounted() {},
    methods: {
        update() {
            this.$emit("input", this.model);
        },
    },
};
</script>

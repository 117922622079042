<template>
    <v-card v-resize="onResize" flast class="pa-10">
        <v-card-text>
            <v-select v-if="!db" :items="$store.getters['database/getDatabaseList']" v-model="db" filled label="Database" dense class="text-uppercase"></v-select>
            <v-row v-if="db" align="center">
                <v-col cols="2" class="text-left">
                    <v-icon large>{{ getIcon(db) }}</v-icon>
                    <v-btn text @click="invert = !invert"><v-icon v-if="invert">fas fa-caret-down</v-icon><v-icon v-if="!invert">fas fa-caret-up</v-icon></v-btn>
                    <v-checkbox
                        v-if="$store.getters['database/hasDatabaseDatafield'](db, 'active')"
                        v-model="toggleActive"
                        label="##&en Activate on click ##&hu Aktiválás klikkre ##"
                    ></v-checkbox>
                </v-col>

                <v-col cols="4" class="text-left">
                    <h1 class="text-uppercase">{{ filter().length }} {{ db }}</h1>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="searchterm" label="filter" prepend-icon="mdi-card-search" persistent-hint></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>

        <v-virtual-scroll v-if="db && $store.getters['database/hasDatabaseDatafield'](this.database, 'active')" dense :items="filter()" :height="max_height" item-height="60">
            <template v-slot:default="{ item }">
                <v-list-item :key="item._id" @click="open(item)">
                    <v-checkbox v-model="item.active" disabled></v-checkbox>
                    <h3>{{ item.name || item[Object.keys(item)[1]] || item._id }}</h3>
                </v-list-item>
                <v-divider></v-divider>
            </template>
        </v-virtual-scroll>

        <v-virtual-scroll v-if="db && !$store.getters['database/hasDatabaseDatafield'](this.database, 'active')" dense :items="filter()" :height="max_height" item-height="60">
            <template v-slot:default="{ item }">
                <v-list-item :key="item._id" @click="open(item)">
                    <h3>{{ item.name || item[Object.keys(item)[1]] || item._id }}</h3>
                </v-list-item>
                <v-divider></v-divider>
            </template>
        </v-virtual-scroll>

        <v-card-actions>
            <v-btn v-if="dropEnabled()" class="mx-2" fab color="primary" @click="drop()"> <v-icon> mdi-delete </v-icon> </v-btn>
            <database-json-upload :db="db" :key="db + '-json-upload'" v-if="addEnabled()" />
            <database-json-download :db="db" :key="db + '-json-download'" />
            <database-dialog-table :db="db" :items="filter()" :key="db" />
            <v-spacer> </v-spacer>
            <v-btn class="mx-2" fab dark color="primary" @click="plus()" v-if="addEnabled()"> <v-icon> mdi-plus </v-icon></v-btn>
        </v-card-actions>
        <database-document-dialog v-if="db" :db="db" :document="document" v-model="dialog" :key="key" />
    </v-card>
</template>
<script>
// The database prop decides, use fixed database or a dropdown selector
import DatabaseDocumentDialog from "@/database-components/database-document-dialog.vue";

import DatabaseJsonUpload from "@/database-components/database-json-upload.vue";
import DatabaseJsonDownload from "@/database-components/database-json-download.vue";
import DatabaseDialogTable from "@/database-components/database-dialog-table.vue";

import filterBySearchterm from "@/database-components/filterBySearchterm.js";

function hasWord(value) {
    return value >= 10;
}

export default {
    name: "database-card",
    props: ["database"],
    components: { DatabaseDocumentDialog, DatabaseJsonUpload, DatabaseJsonDownload, DatabaseDialogTable },
    data: function () {
        /*let db = null;
        let database = this.database || null;
        if (this.$route.params.db) database = this.$route.params.db;
        if (database) if (this.$store.getters['database/getDatabaseList'].includes(database)) db = database;
		*/
        return {
            max_height: Math.floor(window.innerHeight - 480),
            select: "none",
            db: this.database,
            document: null,
            dialog: false,
            key: 0,
            invert: false,
            searchterm: this.$route.hash.substring(1),
            toggleActive: false,
        };
    },
    // TODO achive full responsiveness, make the card fill the screen, but without scollbars on the side of the screen?
    mounted() {
        this.onResize();
    },
    created() {
        this.checkURI();
    },
    methods: {
        dropEnabled() {
            return true;
        },
        drop() {
            let payload = {};
            // compose the payload
            payload.db = this.db;
            payload.op = "drop";
            payload.documents = [];

            //console.log(payload);

            // send the event
            this.$socket.client.emit("database-operation", payload, function (result) {
                if (result.error) alert(result.error);
            });
        },
        onResize() {
            if (window.innerHeight > 400) this.max_height = Math.floor(window.innerHeight - 400);
        },
        filter() {
            if (!this.db) return [];
            const data = this.$store.getters["database/getDatabaseArrayData"](this.db);
            if (!data) return [];
            if (this.invert) return Array.from(ß.filterByValues(data, this.searchterm)).reverse();

            return filterBySearchterm(data, this.searchterm);
        },
        hasSchema() {
            if (this.$store.getters["database/getDatabaseFormat"](this.db)) return true;
            console.log("Database has no format: " + this.db);
            return false;
        },
        open(item) {
            if (this.toggleActive) return this.setActive(item);
            this.key++;
            this.document = item;
            this.dialog = true;
            //console.log("open", this.db, this.document);
            if (this.$route.path !== "/database/" + this.db + "/" + this.document._id) this.$router.push("/database/" + this.db + "/" + this.document._id);
        },
        setActive(doc) {
            if (!doc._id) return console.log("Document has no id!", doc);

            let payload = {};
            // compose the payload
            payload.db = this.db;
            payload.op = "save";
            payload.documents = [{ ...doc, active: !doc.active }];

            //console.log(payload);

            // send the event
            this.$socket.client.emit("database-operation", payload, function (result) {
                if (result.error) alert(result.error);
            });
        },
        plus() {
            this.key++;
            this.document = {};
            this.dialog = true;
        },
        checkURI() {
            if (this.$route.params.db) if (this.$store.getters["database/getDatabaseList"].includes(this.$route.params.db)) this.db = this.$route.params.db;

            if (this.$route.params.id)
                if (this.$store.getters["database/getDocumentById"](this.db, this.$route.params.id)) {
                    this.document = this.$store.getters["database/getDocumentById"](this.db, this.$route.params.id);
                    this.open(this.document);
                }

            if (!this.$route.params.id) this.dialog = false;
        },
        getIcon(db) {
            let icon = "mdi-database";
            const dba = this.$store.state.server.session.databases;
            if (!dba) return icon;
            if (!dba[db]) return icon;
            if (dba[db].read) icon = "mdi-database-outline";
            if (dba[db].comment) icon = "mdi-database-edit-outline";
            if (dba[db].modify) icon = "mdi-database-edit";

            return icon;
        },
        addEnabled() {
            const db = this.db;
            const dba = this.$store.state.server.session.databases;
            if (!dba) return true;
            if (!dba[db]) return true;

            if (dba[db].modify) return true;

            return false;
        },
    },
    watch: {
        "$route.params"(to, from) {
            this.checkURI();
        },
        "$route.params.db"(to, from) {
            if (to) this.checkURI();
        },
        "$route.params.id"(to, from) {
            if (to) this.checkURI();
        },
        dialog(to, from) {
            if (to === false) if (this.$route.path !== "/database/" + this.db) this.$router.push("/database/" + this.db);
        },
    },
};
</script>
